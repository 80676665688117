import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

import COLORS from "../utils/colors";

const AggregationPieChart = ({ buckets }) => {
  const getColor = (key, idx) => {
    return COLORS[idx % COLORS.length];
  };

  const makeRechartsPieBarData = (buckets) => {
    const numberOfBuckets = 100 - 1;
    let rcdata = buckets.slice(0, numberOfBuckets).map((bucket) => {
      let rcbucket = {};
      rcbucket["key"] = bucket["key"];
      rcbucket["Total"] = bucket["doc_count"];
      return rcbucket;
    });

    if (buckets.length > numberOfBuckets) {
      const otherTotal = buckets
        .slice(numberOfBuckets)
        .reduce((total, bucket) => total + bucket["doc_count"], 0);
      rcdata.push({ key: "Other", Total: otherTotal });
    }

    return rcdata;
  };
  return (
    <ResponsiveContainer>
      <PieChart width={"100%"} height={100}>
        <Pie
          data={makeRechartsPieBarData(buckets)}
          nameKey="key"
          dataKey="Total"
          stroke="none"
        >
          {makeRechartsPieBarData(buckets).map((entry, idx) => (
            <Cell key={idx} fill={getColor(entry.key, idx)} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};
export default AggregationPieChart;

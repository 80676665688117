import { useState, useCallback, useEffect } from "react";

import "react-loading-skeleton/dist/skeleton.css";
import debounce from "lodash.debounce";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import {
  BarChart,
  Bar,
  Brush,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";

import AggregationList from "../filterAggregation/AggregationList";
import useResults from "../hooks/useResults";
import Error from "../layout/Error";
import HtmlSnippet from "../layout/HtmlSnippet";
import Loading from "../layout/Loading";
import Pagination from "../navigation/Pagination";
import SearchHeaderRecords from "../search/SearchHeaderRecords";
import SearchOrder from "../search/SearchOrder";

const LetterList = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Letter`;
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultFrom = 1815;
  const defaultTo = 1865;
  const yearFrom = parseInt(searchParams.get("yearFrom") || defaultFrom);
  const yearTo = parseInt(searchParams.get("yearTo") || defaultTo);
  const startDecade = 1 * (yearFrom / 1);
  const endDecade = 1 * (yearTo / 1);

  const keyword = searchParams.get("keyword") || "";
  const apiPath = `${process.env.REACT_APP_BACKEND_URL}/search/letters`;

  let xDomain = [];

  const [aggregations, setAggregations] = useState({});

  // const [range, setRange] = useState({
  //   yearFrom: startDecade,
  //   yearTo: endDecade,
  //   year: true,
  // });

  const [newInitial, setNewInitial] = useState(false);
  const initalRange = {
    yearFrom: defaultFrom,
    yearTo: defaultTo,
    year: false,
  };

  // useEffect(() => {
  //   const newRange = {
  //     yearFrom: yearFrom,
  //     yearTo: yearTo,
  //     year: true,
  //   };
  //   setRange(newRange);
  // }, [yearFrom, yearTo]);

  const handleBrushChange = (range) => {
    const newRange = {
      yearFrom: xDomain[range.startIndex],
      yearTo: xDomain[range.endIndex],
      year: true,
    };

    debouncedSearchChange(newRange);
  };

  const updateRange = (range) => {
    let newSearchParams = new URL(document.location).searchParams;

    newSearchParams.set("yearFrom", range.yearFrom);
    newSearchParams.set("yearTo", range.yearTo);
    newSearchParams.set("year", range.year);

    setSearchParams(newSearchParams, { replace: true });
  };

  const debouncedSearchChange = useCallback(
    debounce((range) => updateRange(range), 400),
    []
  );

  const handleBarClick = (data, index) => {
    const newRange = {
      yearFrom: xDomain[index],
      yearTo: xDomain[index],
      year: true,
    };
    updateRange(newRange);
    setNewInitial(!newInitial);
  };

  const {
    aggregations: initialAggregations,
    isLoading: initialIsLoading,
    error: initialError,
  } = useResults(apiPath, initalRange, true, newInitial) || {};

  const yearKey = Object.keys(initialAggregations).find(
    (aggregation) => aggregation === "year"
  );

  const yearData = yearKey
    ? initialAggregations[yearKey].buckets
        .filter((item) => item.key >= 1815 && item.key <= 1865)
        .sort((a, b) => a.key - b.key)
    : [];
  const years = yearData.map((item) => item.key);

  const max = Math.max(...years);
  const min = Math.min(...years);

  let data = [];
  for (let i = min; i <= max; i++) {
    xDomain.push(i);
    const yearDataItem = yearData.find((item) => item.key === i);
    const yearDataItemDocCount = yearDataItem ? yearDataItem.doc_count : 0;
    data.push({ key: i, doc_count: yearDataItemDocCount });
  }

  const startIndex =
    -1 !== xDomain.indexOf(startDecade) ? xDomain.indexOf(startDecade) : 0;
  const endIndex =
    -1 !== xDomain.indexOf(endDecade)
      ? xDomain.indexOf(endDecade)
      : xDomain.length - 1;

  const barColor = "#c0782a";
  const brushColor = "#c0782a";
  const tooltipBackground = "#ffffff";
  const lineColor = "#1f2222";

  const {
    total,
    items,
    aggregations: newAggregations,
    pagination,
    isLoading,
    error,
  } = useResults(apiPath);

  useEffect(() => {
    if (
      !isLoading &&
      JSON.stringify(newAggregations) !== JSON.stringify(aggregations)
    ) {
      setAggregations(newAggregations);
    }
  }, [aggregations, newAggregations, isLoading]);

  return (
    <div className="container page-main">
      <div className="row">
        <SearchHeaderRecords
          placeholder="Search letters…"
          aggregations={aggregations}
          isLoading={isLoading}
        />
      </div>

      {error.message !== null ? (
        <Error error={error} />
      ) : (
        <>
          <div className="d-flex flex-wrap justify-content-between align-items-center fw-bold mb-3">
            {isLoading ? (
              <Skeleton containerClassName="flex-grow-1" baseColor="#f8f2d8" />
            ) : (
              <>
                {total === 0 ? (
                  <div className="fw-bold">
                    "No Results for the current search criteria."
                  </div>
                ) : (
                  <>
                    <div className="d-flex flex-wrap">
                      <div className="flex-fill me-1">
                        Number of Results:{" "}
                        {pagination.totalResults.toLocaleString()}
                        {"."}
                      </div>
                      <div className="flex-fill">
                        Showing {pagination.from.toLocaleString()} to{" "}
                        {pagination.to.toLocaleString()}
                        {"."}
                      </div>
                    </div>
                    {total > 0 && (
                      <div className="">
                        <SearchOrder />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {total > 0 && (
            <>
              <div className="row mt-4 mb-2">
                <div className="col-12">
                  {initialIsLoading ? (
                    <Skeleton height="200px" baseColor="#f8f2d8" />
                  ) : (
                    <>
                      {data.length > 0 && (
                        <div style={{ height: "200px" }}>
                          <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                              width={500}
                              height={200}
                              data={data}
                              margin={{
                                top: 10,
                                right: 0,
                                left: 0,
                                bottom: 20,
                              }}
                            >
                              <CartesianGrid
                                strokeDasharray="3 3"
                                stroke={lineColor}
                              />
                              <XAxis
                                dataKey="key"
                                interval={"preserveStartEnd"}
                                domain={[
                                  xDomain[0],
                                  xDomain[xDomain.length - 1],
                                ]}
                                stroke={lineColor}
                                axisLine="true"
                                fontFamily="clarendon-urw"
                                // scale={"linear"}
                              >
                                <Label
                                  value="Year"
                                  style={{ fill: brushColor }}
                                  offset={-40}
                                  position="insideBottom"
                                  fontFamily="clarendon-urw"
                                />
                              </XAxis>
                              <YAxis
                                type="number"
                                stroke={lineColor}
                                fontFamily="clarendon-urw"
                                // tickCount={15}
                                interval={1}
                              >
                                <Label
                                  value="No. of Letters"
                                  style={{ fill: brushColor }}
                                  angle={-90}
                                  offset={10}
                                  position="insideBottomLeft"
                                  fontFamily="clarendon-urw"
                                />
                              </YAxis>
                              <Tooltip
                                contentStyle={{
                                  backgroundColor: tooltipBackground,
                                }}
                                formatter={(value, name, props) => [
                                  value,
                                  "No. of Letters:",
                                ]}
                                labelFormatter={(value) => `Year: ${value}`}
                              />
                              <Bar
                                dataKey="doc_count"
                                fill={barColor}
                                onClick={handleBarClick}
                              />
                              <Brush
                                dataKey="key"
                                height={20}
                                stroke={brushColor}
                                onChange={(range) => handleBrushChange(range)}
                                travellerWidth={5}
                                // startIndex={0}
                                // endIndex={46}
                                startIndex={startIndex}
                                endIndex={endIndex}
                              >
                                <BarChart>
                                  <Bar dataKey="doc_count" fill={barColor} />
                                </BarChart>
                              </Brush>
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="d-none d-sm-block col-3">
                  <div className="row fw-bold pb-2 ">
                    <div className="col-4">Filters</div>
                  </div>
                  <AggregationList aggregations={aggregations} />
                </div>
                <div className="col-12 col-sm-9">
                  <div className="mb-3">
                    <div className="row fw-bold pb-2 border-bottom border-primary">
                      <div className="col-6 col-md-4">To</div>
                      <div className="col-3  col-xxl-2 d-none d-md-block">
                        Location
                      </div>
                      <div className="col-4 col-md-3 col-xxl-4">Date</div>
                      <div className="col-2"></div>
                    </div>
                    <div>
                      {isLoading ? (
                        <Skeleton
                          count={10}
                          height="30px"
                          baseColor="#f8f2d8"
                        />
                      ) : (
                        <>
                          {items.map((hit) => {
                            // set to midday to avoid timezone issues
                            const date = new Date(
                              new Date(hit._source.date).setUTCHours(12)
                            );

                            const highlights = [
                              ...new Set([
                                ...(hit.highlight?.plain || []),
                                ...(hit.highlight?.["plain.exact"] || []),
                              ]),
                            ];

                            return (
                              <div
                                key={hit._source.slug}
                                className="border-bottom border-2 border-primary"
                              >
                                <div className="row my-2 letter-list-item">
                                  <div className="col-6 col-md-4">
                                    {hit._source.to ? hit._source.to : "—"}
                                  </div>
                                  <div className="col-3 d-none d-md-block col-xxl-2">
                                    {hit._source.location
                                      ? hit._source.location
                                      : "—"}
                                  </div>
                                  <div className="col-4 col-md-3 col-xxl-4">
                                    {hit._source.date ? (
                                      <>
                                        <div className="d-none d-xxl-block">
                                          {date.toLocaleString("en-GB", {
                                            dateStyle: "full",
                                          })}
                                        </div>
                                        <div className="d-none d-lg-block d-xxl-none">
                                          {date.toLocaleString("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                          })}
                                        </div>
                                        <div className="d-block d-lg-none">
                                          {date.toLocaleString("en-GB", {
                                            // dateStyle: "full",
                                            // weekday: "long",
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                          })}
                                        </div>
                                      </>
                                    ) : (
                                      <>—</>
                                    )}
                                  </div>
                                  <div className="col-2">
                                    <Link
                                      to={`/letter/${hit._source.slug}?keyword=${keyword}`}
                                      className="btn btn-sm btn-primary float-end btn-view-letter"
                                    >
                                      View
                                    </Link>
                                  </div>
                                </div>

                                {highlights.length > 0 && (
                                  <div className="row">
                                    <div className="col-12">
                                      <ul className="list-group mb-3">
                                        {highlights.map((highlight, i) => (
                                          <li
                                            key={i}
                                            className="list-group-item bg-transparent border-primary"
                                          >
                                            <HtmlSnippet
                                              snippet={highlight}
                                              element="span"
                                            />
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 d-md-none">
                      <Pagination
                        pagination={pagination}
                        pageRangeDisplayed={1}
                      />
                    </div>
                    <div className="col-12 d-none d-md-block d-xxl-none">
                      <Pagination pagination={pagination} />
                    </div>
                    <div className="col-12 d-none d-xxl-block">
                      <Pagination
                        pagination={pagination}
                        pageRangeDisplayed={3}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LetterList;

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import useFilters from "../hooks/useFilters";

const AggregationBucket = ({ aggregationName, name, count, color }) => {
  const [searchParams, remove] = useFilters(aggregationName, name);

  return (
    <div className="d-flex justify-content-between">
      <div>
        <span
          className="me-2"
          style={{
            backgroundColor: color,
            width: "1em",
            height: "1em",
            display: "inline-block",
          }}
        ></span>
        {count === 0 || name === "[Not Recorded]" ? (
          <span className={`text-break text-muted`}>
            {name} ({count.toLocaleString()})
          </span>
        ) : (
          <Link className={`text-break link-primary`} to={`?${searchParams}`}>
            {name} ({count.toLocaleString()}){" "}
          </Link>
        )}
      </div>
      {remove && (
        <div>
          <Link className={`text-break link-primary`} to={`?${searchParams}`}>
            {remove && (
              // <>[x]</>
              <FontAwesomeIcon icon={faTimes} />
              // <i
              //   className="fa fa-times float-left d-inline-block"
              //   style={{
              //     fontSize: "1.2rem",
              //   }}
              // />
            )}
          </Link>
        </div>
      )}
    </div>
  );
};

AggregationBucket.propTypes = {
  aggregationName: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  pathname: PropTypes.string,
};

export default AggregationBucket;

import {
  faArrowUpAZ,
  faArrowDownAZ,
  faArrowUp19,
  faArrowDown19,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const SearchOrder = () => {
  const icons = {
    "sort-alpha-down": faArrowDownAZ,
    "sort-alpha-up": faArrowUpAZ,
    "sort-numeric-up": faArrowUp19,
    "sort-numeric-down": faArrowDown19,
  };

  const options = [
    {
      key: "relevance",
      name: "Relevance",
      description: "Letters are ordered by their keyword relevance.",
      icon: null,
      hasOrder: false,
    },
    {
      key: "to",
      name: "To",
      description:
        "Letters are ordered by addressee and then by date and finally by location.",
      icon: "sort-alpha-",
      hasOrder: true,
    },
    {
      key: "location",
      name: "Location",
      description:
        "Letters are ordered by location and then by date and finally by addressee.",
      icon: "sort-alpha-",
      hasOrder: true,
    },
    {
      key: "date",
      name: "Date",
      description:
        "Letters are ordered by date and then by addressee and finally by location.",
      icon: "sort-numeric-",
      hasOrder: true,
    },
  ];

  const [searchParams] = useSearchParams();

  const newSearchParams = new URLSearchParams(searchParams);

  const initialSort = searchParams.get("_dynsort") || "to_asc";

  const initialSortParts = initialSort.split("_");

  const initialOrder =
    initialSortParts.length > 1
      ? initialSortParts[initialSortParts.length - 1]
      : null;
  newSearchParams.delete("_dynsort");

  return (
    <div className="d-flex flex-wrap">
      <div className="me-2">Order by:</div>
      <div className="d-flex flex-wrap">
        {options.map((option) => {
          let iconClassDirection = "";
          let iconClass = "";

          const order =
            initialSortParts.slice(0, 1).join("_") === option.key
              ? initialOrder === "asc"
                ? "desc"
                : "asc"
              : "asc";

          const dynsort = option.hasOrder
            ? `${option.key}_${order}`
            : option.key;
          newSearchParams.set("_dynsort", dynsort);

          iconClassDirection =
            initialSortParts[0] === option.key && initialOrder === "desc"
              ? "up"
              : "down";

          iconClass =
            option.icon !== null ? option.icon + iconClassDirection : "";

          const activeClass =
            initialSortParts[0] === option.key ? " link-secondary" : "";

          searchParams.set("page", 1);

          return (
            <div key={option.key} className="me-2">
              <Link
                to={`?${newSearchParams}`}
                className={`sort-link${activeClass}`}
                title={option.description}
                data-tip={option.description}
              >
                {option.name}{" "}
                {iconClass !== "" && (
                  <FontAwesomeIcon icon={icons[iconClass]} />
                )}
              </Link>
              <Tooltip place="top" effect="solid" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default SearchOrder;

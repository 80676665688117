import { Link, useOutletContext } from "react-router-dom";

import HtmlSnippet from "../layout/HtmlSnippet";
import SearchHeaderRecords from "../search/SearchHeaderRecords";

const VirtualExhibitionList = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Virtual Exhibition`;

  const [results, aggregations] = useOutletContext();

  return (
    <>
      <div className="row mb-3">
        <h1 className="col-12 fs-1">Virtual Exhibition</h1>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <p>
            This online exhibition brings together material from over half of
            the 150 libraries, museums and archives worldwide that hold letters
            by Richard Cobden. The exhibition is a tangible demonstration of the
            reach of Cobden’s influence and the range of his contacts and
            interests. It also provides a useful reminder of the materiality of
            the original letters – something lost when relying on digital
            transcripts alone. We hope that these letters, put in context by
            fully searchable captions, will be useful to educators and students
            as well as interesting to the general reader.{" "}
          </p>
          <p>
            NB: Transcripts are provided from the database where available.
            Where they are not available this is usually because they have
            previously been published in one of the volumes edited by Anthony
            Howe and Simon Morgan and published by Oxford University Press.
          </p>
        </div>
      </div>
      <SearchHeaderRecords
        placeholder="Search virtual exhibition…"
        aggregations={aggregations}
      />
      <div className="row mt-2">
        <div className="col-12">
          <div className="mb-3 row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 g-3">
            {results.map((archive) => (
              <div key={archive.archive_slug} className="col">
                <div
                  key={archive.archive_slug}
                  className="p-3 h-100 border border-primary rounded-3"
                >
                  <h1 className="fs-5">
                    <Link to={`/virtual-exhibition/${archive.archive_slug}`}>
                      {" "}
                      {archive.archive}
                    </Link>
                  </h1>
                  {archive.letters.map((letter, letterIndex) => (
                    <div
                      className={`${
                        letterIndex > 0 ? " border-top border-secondary" : " "
                      } ${
                        letterIndex < archive.letters.length - 1 ? " mb-3" : " "
                      }`}
                      key={letter.letter_slug}
                    >
                      <h2 className="mt-3 fs-6">
                        <Link
                          to={`/virtual-exhibition/${archive.archive_slug}/${letter.letter_slug}`}
                        >
                          {letter.letter}
                        </Link>
                      </h2>
                      <div className="my-1 pb-1 d-flex col overflow-x-scroll custom-scrollbar">
                        {letter.images.map((image) => (
                          <div key={image.image} className="pe-1 col-3">
                            <Link
                              to={`/virtual-exhibition/${archive.archive_slug}/${letter.letter_slug}/${image.image}`}
                            >
                              <picture>
                                <source
                                  srcSet={`${process.env.REACT_APP_SAN_URL}virtual-exhibition/${archive.archive_slug}/${letter.letter_slug}/${image.image}_thumb.avif`}
                                  type="image/avif"
                                />
                                <source
                                  srcSet={`${process.env.REACT_APP_SAN_URL}virtual-exhibition/${archive.archive_slug}/${letter.letter_slug}/${image.image}_thumb.webp`}
                                  type="image/webp"
                                />
                                <img
                                  src={`${process.env.REACT_APP_SAN_URL}virtual-exhibition/${archive.archive_slug}/${letter.letter_slug}/${image.image}_thumb.jpg`}
                                  alt="thumbnail"
                                  className="img-fluid border border-2 border-primary"
                                  loading="lazy"
                                  height={80}
                                  width={80}
                                />
                              </picture>
                            </Link>
                          </div>
                        ))}
                      </div>
                      {letter.caption && (
                        <HtmlSnippet
                          snippet={letter.caption}
                          style={{ height: "100px" }}
                          forbiddenTags={["style", "p"]}
                          className="text-break overflow-y-scroll custom-scrollbar"
                        />
                      )}
                      <div
                        className="mt-1 overflow-x-scroll custom-scrollbar"
                        style={{ height: "60px" }}
                      >
                        {letter.keywords.length > 0 && (
                          <ul className="list-unstyled list-inline m-0">
                            {letter.keywords.map((keyword) => (
                              <li
                                key={keyword}
                                className="list-inline-item me-4 fw-bold"
                              >
                                <Link
                                  to={`/virtual-exhibition?_filters[]=keywords|${keyword}`}
                                >
                                  {keyword}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default VirtualExhibitionList;

import { useEffect } from "react";

import { useParams } from "react-router-dom";

import useAxios from "../hooks/useAxios";
import Error from "../layout/Error";
import HtmlSnippet from "../layout/HtmlSnippet";
import Loading from "../layout/Loading";

const ExplanatoryEssayShow = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Explanatory Essays`;
  const slug = useParams().slug;

  const apiPath = `${process.env.REACT_APP_BACKEND_URL}/api/explanatory-essay/${slug}`;

  const { data, isLoading, error } = useAxios(apiPath);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Explanatory Essays: ${data.title}`;
  }, [data]);

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <div className="mb-4">
              <div className="row mb-3">
                <h1>Explanatory Essays: {data.title}</h1>
              </div>
              <div>
                <HtmlSnippet snippet={data.content} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ExplanatoryEssayShow;

import React, { useRef } from "react";

import { Link, useParams, useOutletContext } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import VirtualExhibitionArchiveLetterShow from "./VirtualExhibitionArchiveLetterShow";
import HtmlSnippet from "../layout/HtmlSnippet";

const VirtualExhibitionArchiveShow = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Virtual Exhibition Archive`;
  const [results] = useOutletContext();
  const transformWrapperRef = useRef();

  let { archiveSlug, letterSlug, imageSlug } = useParams();

  if (!archiveSlug) {
    return null;
  }

  const result = results.find((result) => result.archive_slug === archiveSlug);

  if (!letterSlug) {
    letterSlug = result.letters[0].letter_slug;
  }

  const activeLetter = result.letters.find(
    (letter) => letter.letter_slug === letterSlug
  );

  if (!imageSlug) {
    imageSlug = activeLetter.images[0].image;
  }

  const activeExhibit = result.letters
    .flatMap((letter) => letter.images)
    .find((exhibit) => exhibit.image === imageSlug)
    ? result.letters
        .flatMap((letter) => letter.images)
        .find((exhibit) => exhibit.image === imageSlug)
    : result.letters.flatMap((letter) => letter.images)[0];
  return (
    <div className="">
      <div className="row mb-2">
        <h1 className="col-12">{result.archive}</h1>
      </div>
      <div className="row mb-2">
        <div className="col-12">
          <ul className="nav-tabs d-flex list-unstyled mb-0" id="letter-nav">
            {result.letters.map((letter) => (
              <li key={letter.letter_slug} className="nav-item">
                <Link
                  to={`/virtual-exhibition/${result.archive_slug}/${letter.letter_slug}/${letter.images[0].image}#letter-nav`}
                  className={`nav-link px-2 ${
                    result.archive_slug === archiveSlug &&
                    letter.letter_slug === letterSlug
                      ? "active"
                      : ""
                  }`}
                >
                  {letter.letter}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div
        className="mb-5 archive-letter"
        key={activeLetter.letter_slug}
        id={`letter-${activeLetter.letter_slug}`}
      >
        <div className="d-flex justify-content-center mb-3 border border-1 border-primary p-1">
          <TransformWrapper
            initialScale={1}
            minScale={0.5}
            maxScale={16}
            smooth={false}
            ref={transformWrapperRef}
          >
            {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
              <>
                <div className="zoom-pan-pinch-controlPanel">
                  <button
                    type="button"
                    className="zoom-pan-pinch-controlBtn mb-2"
                    onClick={() => zoomIn()}
                  >
                    Zoom In +
                  </button>
                  <button
                    type="button"
                    className="zoom-pan-pinch-controlBtn mb-2"
                    onClick={() => zoomOut()}
                  >
                    Zoom Out -
                  </button>
                  <button
                    type="button"
                    className="zoom-pan-pinch-controlBtn mb-2"
                    onClick={() => resetTransform()}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    className="zoom-pan-pinch-controlBtn mb-2"
                    onClick={() => centerView()}
                  >
                    Center
                  </button>
                </div>
                <TransformComponent>
                  <div className="text-center letter-wrapper">
                    <picture>
                      <source
                        srcSet={`${process.env.REACT_APP_SAN_URL}/virtual-exhibition/${result.archive_slug}/${activeLetter.letter_slug}/${activeExhibit.image}.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${process.env.REACT_APP_SAN_URL}/virtual-exhibition/${result.archive_slug}/${activeLetter.letter_slug}/${activeExhibit.image}.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${process.env.REACT_APP_SAN_URL}/virtual-exhibition/${result.archive_slug}/${activeLetter.letter_slug}/${activeExhibit.image}.jpg`}
                        alt="letter"
                        className="letter-image"
                        loading="lazy"
                        width={activeExhibit.width}
                        height={activeExhibit.height}
                      />
                    </picture>
                  </div>
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </div>

        <div className="mb-4 d-flex justify-content-center overflow-x-scroll custom-scrollbar">
          <div className="d-flex overflow-x-scroll custom-scrollbar pb-1">
            {activeLetter.images.map((exhibit, exhibitIndex) => (
              <div key={exhibit.image} className={``}>
                <div
                  className={`m-1 p-1 border ${
                    exhibit.image === imageSlug ||
                    (letterSlug !== activeLetter.letter_slug &&
                      exhibitIndex === 0)
                      ? "border-3 border-secondary"
                      : "border-2 border-primary"
                  }`}
                  onClick={() => {
                    transformWrapperRef.current.resetTransform();
                  }}
                >
                  <Link
                    to={`/virtual-exhibition/${result.archive_slug}/${activeLetter.letter_slug}/${exhibit.image}#letter-nav`}
                  >
                    <picture>
                      <source
                        srcSet={`${process.env.REACT_APP_SAN_URL}/virtual-exhibition/${result.archive_slug}/${activeLetter.letter_slug}/${exhibit.image}_thumb.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${process.env.REACT_APP_SAN_URL}/virtual-exhibition/${result.archive_slug}/${activeLetter.letter_slug}/${exhibit.image}_thumb.webp`}
                        type="image/webp"
                      />
                      <img
                        width="80"
                        height="80"
                        src={`${process.env.REACT_APP_SAN_URL}/virtual-exhibition/${result.archive_slug}/${activeLetter.letter_slug}/${exhibit.image}_thumb.jpg`}
                        alt="thumbnail"
                        className={`img-fluid`}
                        loading="lazy"
                      />
                    </picture>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
        {activeLetter.caption && <HtmlSnippet snippet={activeLetter.caption} />}
        {activeLetter.keywords.length > 0 && (
          <div className="mt-4 d-md-flex align-items-start">
            <h4 className="mb-0 mb-2 mb-md-0 me-md-4 fs-5">Keywords:</h4>
            <ul className="list-unstyled list-inline m-0">
              {activeLetter.keywords.map((keyword) => (
                <li key={keyword} className="list-inline-item me-4 fw-bold">
                  <Link
                    to={`/virtual-exhibition?_filters[]=keywords|${keyword}`}
                  >
                    {keyword}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
        {activeLetter.xml_slug !== "" ? (
          <>
            <h3 className="mt-5 fs-4">
              <Link to={`/letter/${activeLetter.xml_slug}`}>Transcript</Link>
            </h3>
            <VirtualExhibitionArchiveLetterShow slug={activeLetter.xml_slug} />
          </>
        ) : (
          <div className="mt-4">
            <strong>Transcript unavailable.</strong>
          </div>
        )}
      </div>
    </div>
  );
};

export default VirtualExhibitionArchiveShow;

const NotFound = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - 404 Not Found`;

  return (
    <div className="">
      <h1>404 - Not Found!</h1>
    </div>
  );
};

export default NotFound;

import ahrc from "../images/ahrc.svg";
import dhi from "../images/dhi.png";
import lb from "../images/leeds-beckett.svg";
import uea from "../images/uea.png";

const Footer = () => {
  return (
    <footer className="site-footer mt-4">
      {/* <div className="floral"></div> */}
      <div className="container py-4">
        <div className="d-md-flex justify-content-between align-items-center text-center">
          <div className="mb-5 mb-md-0">
            <a href="https://www.leedsbeckett.ac.uk/">
              <img
                src={lb}
                alt="Leeds Beckett University"
                width="142"
                height="60"
              />
            </a>
          </div>
          <div className="mb-5 mb-md-0">
            <a href="https://www.uea.ac.uk/">
              <img
                src={uea}
                alt="University of East Anglia"
                width="174"
                height="36"
              />
            </a>
          </div>
          <div className="mb-5 mb-md-0">
            <a href="https://www.dhi.ac.uk/">
              <img
                src={dhi}
                alt="Digital Humanities Institute"
                width="90"
                height="60"
              />
            </a>
          </div>
          <div className="mb-5 mb-md-0">
            <a href="https://www.ukri.org/councils/ahrc/">
              <img src={ahrc} alt="AHRC" width="196" height="50" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;

import { useSearchParams } from "react-router-dom";

import useAxios from "./useAxios";

const useResults = (apiPath, params = {}) => {
  const [searchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams.toString());

  Object.keys(params).forEach((key) => {
    newSearchParams.set(key, params[key]);
  });

  const exclude = ["lat", "lng", "zoom", "markerType"];
  exclude.forEach((key) => {
    newSearchParams.delete(key);
  });

  const { data, isLoading, error } = useAxios(
    `${apiPath}?${newSearchParams.toString()}`,
    [],
    [apiPath, newSearchParams.toString()],
    false
  );

  return {
    total: data.hits?.total?.value ? data.hits.total.value : 0,
    items: data.hits?.hits ? data.hits.hits : [],
    aggregations: data.aggregations ? data.aggregations : {},
    pagination: data.pagination ? data.pagination : {},
    isLoading: isLoading,
    error: error,
  };
};

export default useResults;

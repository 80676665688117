const DiscriminatoryLanguage = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Discriminatory language in Cobden’s letters`;

  return (
    <div className="container page-main">
      <div className="">
        <h1 className="">Discriminatory language in Cobden’s letters</h1>
        <p className="">
          From around the 1850s onwards, the casual use of the ‘N’ word to
          describe people of colour became widespread in British public
          discourse. This was largely due to the influence of black-faced
          minstrelsy, which used the term to describe the African American
          slaves (and later free plantation workers) who the minstrels parodied
          as happy-go-lucky, indolent and stupid. At first these consisted of
          American touring groups, but they were soon joined by home-grown
          examples. By the end of the 1850s minstrelsy was being casually
          described as ‘N - - entertainment’ in newspapers (e.g. Standard, 23
          Nov. 1859). However, as Cobden’s letters show, during the 1850s and
          1860s the use of the word was still contested, and its status as a
          derogatory and aggressive term – later downplayed – was well-known.
        </p>
        <p className="">
          Cobden uses the term in only a handful of letters, and usually with
          disapproval. On 24 August 1857 he wrote to John Bright expressing his
          dismay at learning from his niece that the term was routinely used by
          the British in India to refer to their South Asian servants – a fact
          to which he attributed much of the resentment that had prompted the
          outbreak of the great Indian rebellion of that year. During the
          American Civil War, he wrote a flurry of letters in 1863 using the
          term ironically, in inverted commas, where he exults that Lincoln’s
          Emancipation Proclamation, which abolished slavery in the Confederate
          states and allowed the recruitment of African Americans to the Union
          army, would mean that ‘The much despised “N - - ” will I suspect yet
          prove the arbitrator in this terrible struggle’ (e.g. to Michel
          Chevalier, 20 Jan. 1863). The last such letter was to his brother-in-
          law John Williams on 27 October 1863.
        </p>
        <p className="">
          However, in 1864 there are two letters from August and September where
          Cobden uses the term casually and unreflectively as a collective noun,
          when quoting John Bright’s opinion that African American men were
          likely to gain voting rights in the post-war South before the British
          working class. Given his earlier conscious disapproval of the word,
          this may be taken as depressing evidence for the speed with which it
          had become normalised, and white middle-class Britons desensitised to
          its use, even as American slavery itself was in its death throes.
        </p>
      </div>
    </div>
  );
};

export default DiscriminatoryLanguage;

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams, useParams, Link } from "react-router-dom";

import FormFilter from "../form/FormFilter";
import useForm from "../hooks/useForm";

const SearchHeaderRecords = ({
  placeholder = "Search…",
  aggregations = {},
  isLoading = true,
}) => {
  const filterLabels = Object.fromEntries(
    Object.entries(aggregations).map(([key, value]) => [
      value.field,
      value.label,
    ])
  );

  const [searchParams] = useSearchParams();

  const [values, setValues, handleChange, handleSubmit, handleReset] =
    useForm();

  const filters = searchParams
    .getAll("_filters[]")
    .map((filter) => (
      <FormFilter key={filter} filter={filter} filterLabels={filterLabels} />
    ));

  const yearFilter = searchParams.get("year") ? (
    <FormFilter
      filter={`year|${searchParams.get("yearFrom")}–${searchParams.get(
        "yearTo"
      )}`}
      filterLabels={filterLabels}
    />
  ) : null;

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <div className="input-group">
          <input
            type="search"
            className="form-control"
            name="keyword"
            id="field_keyword"
            placeholder={placeholder}
            value={values["keyword"] || ""}
            onChange={handleChange}
          />

          <button className="btn btn-primary" type="submit" aria-label="Search">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>

      {!isLoading && (
        <div className="row gx-2">
          {filters}
          {yearFilter}
        </div>
      )}
    </form>
  );
};
export default SearchHeaderRecords;

import { Link } from "react-router-dom";

const About = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - About`;

  return (
    <div className="container page-main">
      <div className="mb-5">
        <h1 className="mb-4">Who Was Richard Cobden?</h1>
        <p>
          Richard Cobden (1804-1865) was one of the most influential British
          politicians of the 19<sup>th</sup> century. Although he never held
          government office, Cobden’s ideas about free trade being the basis for
          prosperity for all and peaceful relations between nations became
          popular orthodoxy in Britain for over eighty-five years, from the
          repeal of the Corn Law in 1846 to the return to protectionism in 1932.
          They also exercised great influence across Europe, the Americas and
          the wider world.
        </p>
        <p>
          Today, Cobden is mostly remembered as the leader of the Anti-Corn Law
          League – an organisation set up in 1839 to campaign to remove taxes on
          imported food. Blamed for increasing the cost of living for the poor
          and encouraging corn-exporting countries to retaliate against British
          manufactured goods, the Corn Laws were repealed in 1846. However,
          Cobden was also involved in a wide range of other campaigns and
          causes: from the establishment of a national system of education (not
          successful until after his death), to the removal of the so-called
          ’taxes on knowledge’ which prevented the establishment of affordable
          newspapers for the masses. His belief that free trade would make war
          impossible also saw him become a leading light of the international
          peace movement, alongside other luminaries such as the author Victor
          Hugo and scientist Alexander von Humboldt. In 1860, he successfully
          negotiated a Commercial Treaty with France, which formed the basis of
          a network of such agreements which created the largest peacefully
          achieved European free trade zone before the establishment of the
          Common Market after the Second World War. Details of these and his
          other activities can be found in the Explanatory Essays [link here].
        </p>
        <h2>The Letters of Richard Cobden Online</h2>
        <p>
          Throughout his campaigns, Cobden used his personal correspondence as a
          key method of organising, persuading and sharing his own knowledge and
          experience, while also eliciting new information to help inform his
          speeches and pamphlets. His letters also give an insight into his
          private life, and the stresses and strains of agitation. The Letters
          of Richard Cobden (1804-1865) Online provides free access to digital
          transcripts of Cobden’s letters collected by the Letters of Richard
          Cobden Project, first established in 2002. These are letters not
          previously published in the project’s four-volume hard-copy edition by
          Oxford University Press (see below for full details).
        </p>
        <p>
          The project has been funded by the Arts and Humanities Research
          Council as part of a wider impact and engagement project themed around
          ’active citizenship’. Other activities included an exhibition hosted
          by Archives+ at Manchester Central Library from April-June 2023; a
          ’collection encounter’ at the John Rylands University Library,
          Manchester; a series of workshops with Key-Stage 3 pupils at schools
          in Leeds, Bradford and Rochdale which included leadership coaching by
          the GreenWing Project; and a schools essays prize organised in
          conjunction with the History of Parliament Trust.
        </p>
        {/* <div className="col-12 mb-4"> */}
        <p>This website includes:</p>
        <ul>
          <li>
            A fully searchable database of over{" "}
            <Link to="/letter/">5,000 transcripts of the letters</Link> of this
            important Victorian statesman
          </li>
          <li>
            Free and downloadable{" "}
            <Link to="/teaching-resources/">teaching resources</Link> based on
            the letters, compatible with Key Stage 3 History and Citizenship
            curricula in England &amp; Wales.
          </li>
          <li>Examples of student work from our pilot schools</li>
          <li>
            <Link to="/explanatory-essay/">Explanatory essays</Link> covering
            different aspects of Cobden’s life and campaigns
          </li>
          <li>
            A ’<Link to="/virtual-exhibition/">virtual exhibition</Link>’ of
            scanned manuscript material from more than half of the nearly 150
            archives and private collections worldwide that hold Cobden letters.
          </li>
        </ul>
        {/* </div> */}
        <p>
          The site is a work in progress, and we intend to continue adding to
          the available resources.
        </p>
        <p>
          We are confident that the letters will be an enormous boon to
          nineteenth-century scholarship, as well as providing valuable lessons
          in active citizenship past, present and future for school children and
          anyone with a general interest in the period.
        </p>
        <h2>The Oxford University Press Edition:</h2>
        <p>
          This website complements and completes the selected edition of
          Cobden’s letters edited by Professor Anthony Howe and Professor Simon
          Morgan and published by Oxford University Press between 2007 and 2015.
          The full details of these publications can be found below along with
          links to the OUP website.
        </p>
        <p>
          <a href="https://global.oup.com/academic/product/the-letters-of-richard-cobden-9780199211951?q=letters%20of%20richard%20cobden&lang=en&cc=gb">
            Anthony Howe (ed.),{" "}
            <em>The Letters of Richard Cobden Volume I: 1815-1847</em> (Oxford:
            Oxford University Press, 2007), pp. lx, 529. ISBN 978-0-19-921195-1.
          </a>
        </p>
        <p>
          <a href="https://global.oup.com/academic/product/the-letters-of-richard-cobden-9780199211968?lang=en&cc=gb">
            Anthony Howe (ed.),{" "}
            <em>The Letters of Richard Cobden Volume II: 1848-1853</em> (Oxford:
            Oxford University Press, 2010), pp. xlvii, 616. ISBN
            978-0-19-921196-8.
          </a>
        </p>
        <p>
          <a href="https://global.oup.com/academic/product/the-letters-of-richard-cobden-9780199211975?lang=en&cc=gb">
            Anthony Howe and Simon Morgan (eds),{" "}
            <em>The Letters of Richard Cobden Volume III: 1854-1859</em>{" "}
            (Oxford: Oxford University Press, 2012), pp. xlv, 532. ISBN
            978-0-19-921197-5.
          </a>
        </p>
        <p>
          <a href="https://global.oup.com/academic/product/the-letters-of-richard-cobden-9780199211982?lang=en&cc=gb">
            Anthony Howe and Simon Morgan (eds),{" "}
            <em>The Letters of Richard Cobden Volume IV: 1860-1865</em> (Oxford:
            Oxford University Press, 2015), pp. lxii, 627. ISBN
            978-0-19-921198-2.
          </a>
        </p>
        <h2>The Letters of Richard Cobden Online Project Team are:</h2>
        <ul>
          <li>
            <a href="https://www.leedsbeckett.ac.uk/staff/professor-simon-morgan/">
              Professor Simon Morgan
            </a>
            , Leeds Beckett University (Principal Investigator)
          </li>
          <li>
            <a href="https://research-portal.uea.ac.uk/en/persons/anthony-howe">
              Professor Anthony Howe
            </a>
            , University of East Anglia (Co-Investigator)
          </li>
          <li>
            <a href="https://www.leedsbeckett.ac.uk/staff/dr-helen-dampier/">
              Dr Helen Dampier
            </a>
            , Leeds Beckett University (Co-Investigator).
          </li>
        </ul>
        <p>
          Contact:{" "}
          <a href="mailto:DigitalCobden@leedsbeckett.ac.uk">
            DigitalCobden@leedsbeckett.ac.uk
          </a>
        </p>
      </div>
    </div>
  );
};

export default About;

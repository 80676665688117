import { useSearchParams } from "react-router-dom";

const useFilters = (aggregationName, aggregationValue) => {
  const [searchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  let remove = false;

  const activeFilters = newSearchParams.getAll("_filters[]");

  const bucketFilter = `${aggregationName}|${aggregationValue}`;

  if (aggregationName === "year") {
    newSearchParams.delete("year");
    newSearchParams.delete("yearFrom");
    newSearchParams.delete("yearTo");
  } else if (activeFilters.includes(bucketFilter)) {
    remove = true;
    let newFilters = activeFilters.filter((filter) => filter !== bucketFilter);
    newSearchParams.delete("_filters[]");
    newFilters.forEach((filter) =>
      newSearchParams.append("_filters[]", filter)
    );
  } else {
    newSearchParams.append("_filters[]", bucketFilter);
  }

  return [newSearchParams, remove];
};

export default useFilters;

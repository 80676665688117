import { useLayoutEffect } from "react";

import { useLocation, useSearchParams } from "react-router-dom";

function ScrollToTop() {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const hash = window.location.hash;
  const searchParamsString = searchParams.toString();
  useLayoutEffect(() => {
    if (hash) {
      const sectionId = hash.substring(1);
      const targetElement = document.getElementById(sectionId);

      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scroll(0, 0);
    }
  }, [pathname, searchParamsString, hash]);

  return null;
}

export default ScrollToTop;

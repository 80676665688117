import { Link } from "react-router-dom";

import useAxios from "../hooks/useAxios";
import Error from "../layout/Error";
import HtmlSnippet from "../layout/HtmlSnippet";
import Loading from "../layout/Loading";

const ExplanatoryEssayList = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Explanatory Essays`;
  const apiPath = `${process.env.REACT_APP_BACKEND_URL}/api/explanatory-essay`;

  const { data, isLoading, error } = useAxios(apiPath);

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <div className="">
              <div className="row mb-4">
                <h1>Explanatory Essays</h1>
              </div>
              {data.map((item) => (
                <div key={item.slug} className="mb-4">
                  <div>
                    <h2 className="mb-3">
                      <Link
                        to={`/explanatory-essay/${item.slug}`}
                        // className="text-decoration-none"
                      >
                        {item.title}
                      </Link>
                    </h2>
                    <HtmlSnippet snippet={item.introduction} className="mb-3" />
                    <Link to={`/explanatory-essay/${item.slug}`}>
                      Read more
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ExplanatoryEssayList;

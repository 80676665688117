import useResults from "../hooks/useResults";
import CobdenMap from "../layout/CobdenMap";
import Error from "../layout/Error";
import Loading from "../layout/Loading";

const Map = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Map`;

  const apiPath = `${process.env.REACT_APP_BACKEND_URL}/search/map`;
  const { total, items, aggregations, pagination, isLoading, error } =
    useResults(apiPath);

  return (
    <div className="page-main">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              {/* <div className="row mb-3">
                <h1 className="col-12">Cobden Archive Map</h1>
              </div> */}
              {/* <CobdenMap locationAggregation={aggregations.location.buckets} /> */}
              <iframe
                title="Cobden Archives"
                src="https://www.google.com/maps/d/embed?mid=1CVW0z5b1QvfFF3QRHnvAZDGZJO94RSs&ehbc=2E312F"
                width="100%"
                height="600px"
                style={{ width: "100%", height: "75dvh" }}
              ></iframe>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Map;

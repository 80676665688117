import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import useFilters from "../hooks/useFilters";

const FormFilter = ({ filter = "", filterLabels = [] }) => {
  const filterParts = filter.split("|");
  const [searchParams, remove] = useFilters(filterParts[0], filterParts[1]);

  return (
    <div className="d-grid col-12 col-lg-6 mb-2">
      <Link to={`?${searchParams}`} className="btn btn-secondary text-start">
        <FontAwesomeIcon icon={faTimes} className="me-2" />
        <span className="fw-bold me-1">{filterLabels[filterParts[0]]}</span>
        <span className="fw-light">{filterParts[1]}</span>
      </Link>
    </div>
  );
};

export default FormFilter;

import { useState, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

const useForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const keyword = searchParams.get("keyword") || "";
  const [values, setValues] = useState({
    keyword: keyword,
  });

  useEffect(() => {
    setValues({ keyword: keyword });
  }, [keyword]);

  const handleChange = (event) => {
    let newValues = { ...values };
    newValues[event.target.name] = event.target.checked
      ? event.target.checked
      : event.target.value;
    setValues({ ...newValues });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const initialStringified = searchParams.toString();
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.delete("page");
    newSearchParams.delete("year");
    newSearchParams.delete("yearFrom");
    newSearchParams.delete("yearTo");

    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string") {
        newSearchParams.set(key, values[key]);
      }
    });
    if (initialStringified !== newSearchParams.toString()) {
      setSearchParams(newSearchParams, { replace: true });
    }
  };

  const handleReset = (event) => {
    let newValues = { ...values };
    Object.keys(newValues).forEach((key) => {
      if (typeof newValues[key] === "string" && "" !== newValues[key]) {
        newValues[key] = "";
      }
    });

    setValues(newValues);
  };

  return [values, setValues, handleChange, handleSubmit, handleReset];
};

export default useForm;

import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import DisplayLetter from "./DisplayLetter";
import useAxios from "../hooks/useAxios";
import Error from "../layout/Error";
import Loading from "../layout/Loading";
import SearchHeaderRecords from "../search/SearchHeaderRecords";

const LetterShow = () => {
  const slug = useParams().slug;
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Letter`;
  const apiPath = `${process.env.REACT_APP_BACKEND_URL}/search/letter/${slug}`;
  const { data, isLoading, error } = useAxios(apiPath);
  const highlight = data ? data["highlight"] : { body: [] };

  useEffect(() => {
    if (data._source?.text) {
      document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Letter: ${data._source.to}`;
    }
  }, [data]);

  const letter = data._source;
  console.log(letter);
  return (
    <>
      {!isLoading && error.message === null && data._source.contentWarning && (
        <div className="letter-alert">
          <div className="container">
            <div className="row">
              <div className="col-12 p-3">
                <i className="fas fa-exclamation-triangle ms-1 me-2"></i>
                <strong>Content warning:</strong> This letter contains offensive
                and potentially distressing language. For further context,
                please see this{" "}
                <Link
                  className="link-primary"
                  to="/discriminatory-language-in-cobdens-letters"
                >
                  note
                </Link>
                .
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {error.message !== null ? (
              <Error error={error} />
            ) : (
              <div className="">
                <div className="row mb-0">
                  <SearchHeaderRecords
                    placeholder="Search letter…"
                    aggregations={[]}
                  />
                </div>
                <DisplayLetter letter={letter} highlight={highlight} />
                {letter.virtualExhibition && (
                  <div className="mt-5 fw-bold">
                    View this letter in the{" "}
                    <Link
                      to={`/virtual-exhibition/${letter.virtualExhibitionArchiveSlug}/${letter.virtualExhibitionLetterSlug}`}
                    >
                      virtual exhibition
                    </Link>
                    , as part of the {letter.virtualExhibitionArchive} archive.
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default LetterShow;

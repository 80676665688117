const DOMPurify = require("dompurify")(window);

// TODO switch to https://www.npmjs.com/package/html-react-parser
const HtmlSnippet = ({
  snippet,
  element = "div",
  className = "",
  style = {},
  forbiddenTags = ["style"],
  forbiddenAttr = ["style"],
}) => {
  if (!snippet) {
    return null;
  }
  const cleanHtml = DOMPurify.sanitize(snippet, {
    FORBID_TAGS: forbiddenTags,
    FORBID_ATTR: forbiddenAttr,
  });
  if (element === "span") {
    return (
      <span
        style={style}
        className={className}
        dangerouslySetInnerHTML={{ __html: cleanHtml }}
      ></span>
    );
  }

  return (
    <div
      style={style}
      className={className}
      dangerouslySetInnerHTML={{ __html: cleanHtml }}
    ></div>
  );
};
export default HtmlSnippet;

import React from "react";

import GA4React from "ga-4-react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./scss/app.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "bootstrap/dist/js/bootstrap.min.js";

if (process.env.NODE_ENV === "production") {
  const ga4react = new GA4React(process.env.REACT_APP_GA4);

  (async (_) => {
    await ga4react
      .initialize()
      .then
      // (res) => console.log("Analytics Success.")
      ()
      .catch
      // (err) => console.log("Analytics Failure.")
      ()
      .finally(() => {
        const root = createRoot(document.getElementById("root"));

        root.render(
          <React.StrictMode>
            <BrowserRouter basename={process.env.REACT_APP_UI_BASEURL}>
              <App />
            </BrowserRouter>
          </React.StrictMode>
        );
      });
  })();
} else {
  // In non-production environments (e.g., development), render your app without GA4.
  const root = createRoot(document.getElementById("root"));

  root.render(
    <React.StrictMode>
      <BrowserRouter basename={process.env.REACT_APP_UI_BASEURL}>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

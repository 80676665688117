import React, { useState, useEffect } from "react";

const Loading = () => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true);
    }, 200);

    return () => clearTimeout(timer); // Clean up the timer if the component unmounts
  }, []);

  return (
    <div className="loading-placeholder">
      <div className="row">
        <div className="col-12 mt-4" style={{ height: "100vh" }}>
          {showSpinner && (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Loading;

import { useState, useEffect } from "react";

import axios from "axios";
import { useLocation } from "react-router-dom";

const useAxios = (apiPath, query = [], watchChange = [], withSearch = true) => {
  const location = useLocation();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState({ code: null, message: null });

  const fetchData = () => {
    axios
      .get(`${apiPath}${withSearch ? location.search : ""}`, {
        withCredentials: true,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            setError({
              code: error.response.status,
              message: "An data error has occurred.",
            });
          } else {
            setError({
              code: error.response.status,
              message: "An error has occurred.",
            });
          }
        } else if (("request", error.request)) {
          setError({
            code: 500,
            message: "An request error has occurred.",
          });
        } else {
          setError({
            code: 500,
            message: "An unknown error has occurred.",
          });
        }
        setData({ ...data, items: [] });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    return () => {
      setData([]);
      setIsLoading(false);
      setError({ code: null, message: null });
    };
  }, [...watchChange, location.search, apiPath]);

  return {
    data,
    isLoading,
    error,
  };
};

export default useAxios;

// import { Suspense, lazy } from "react";
// import { useState } from "react";

// import axios from "axios";
import { Routes, Route } from "react-router-dom";

import About from "./components/About";
import DiscriminatoryLanguage from "./components/DiscriminatoryLanguage";
import ExplanatoryEssayList from "./components/ExplanatoryEssayList";
import ExplanatoryEssayShow from "./components/ExplanatoryEssayShow";
import Homepage from "./components/Homepage";
import LetterList from "./components/LetterList";
import LetterShow from "./components/LetterShow";
import Map from "./components/Map";
import NotFound from "./components/NotFound";
import TeachingResources from "./components/TeachingResources";
import VirtualExhibitionArchiveShow from "./components/VirtualExhibitionArchiveShow";
import VirtualExhibitionHome from "./components/VirtualExhibitionHome";
import VirtualExhibitionList from "./components/VirtualExhibitionList";
import ScrollToTop from "./hooks/ScrollToTop";
import Page from "./layout/Page";

// const Homepage = lazy(() => import("./components/Homepage"));

const App = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Prototype`;

  return (
    <>
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Page />}>
          <Route index element={<Homepage />} />
          <Route path="letter/">
            <Route path="" element={<LetterList />} />
            <Route path=":slug/" element={<LetterShow />} />
          </Route>
          <Route path="teaching-resources/" element={<TeachingResources />} />

          <Route path="virtual-exhibition/" element={<VirtualExhibitionHome />}>
            <Route path="" element={<VirtualExhibitionList />} />
            <Route
              path={":archiveSlug"}
              element={<VirtualExhibitionArchiveShow />}
            />
            <Route
              path={":archiveSlug/:letterSlug"}
              element={<VirtualExhibitionArchiveShow />}
            />
            <Route
              path={":archiveSlug/:letterSlug/:imageSlug"}
              element={<VirtualExhibitionArchiveShow />}
            />
          </Route>
          <Route path="explanatory-essay/">
            <Route path="" element={<ExplanatoryEssayList />} />
            <Route path=":slug/" element={<ExplanatoryEssayShow />} />
          </Route>
          <Route path="map/" element={<Map />} />
          <Route path="about/" element={<About />} />
          <Route
            path="discriminatory-language-in-cobdens-letters/"
            element={<DiscriminatoryLanguage />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      {/* </Suspense> */}
    </>
  );
};

export default App;

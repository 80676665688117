import { Link } from "react-router-dom";

import DisplayLetter from "./DisplayLetter";
import useAxios from "../hooks/useAxios";
import Error from "../layout/Error";
import Loading from "../layout/Loading";

const VirtualExhibitionArchiveLetterShow = ({ slug }) => {
  const apiPath = `${process.env.REACT_APP_BACKEND_URL}/search/letter/${slug}`;
  const { data, isLoading, error } = useAxios(apiPath);
  const highlight = data ? data["highlight"] : { body: [] };

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <>
              {data._source.contentWarning && (
                <div className="letter-alert-inline mt-3">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 p-3">
                        <i className="fas fa-exclamation-triangle ms-1 me-2"></i>
                        <strong>Content warning:</strong> This letter contains
                        offensive and potentially distressing language. For
                        further context, please see this{" "}
                        <Link
                          className="link-primary"
                          to="/discriminatory-language-in-cobdens-letters"
                        >
                          note
                        </Link>
                        .
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <DisplayLetter
                letter={data._source}
                highlight={highlight}
                search={false}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default VirtualExhibitionArchiveLetterShow;

import { Fragment, useState, useCallback } from "react";

import { Link, NavLink } from "react-router-dom";

import navigationData from "../navigation/navigation.json"; // Update the path

const Navbar = () => {
  var [toggle, setToggle] = useState(true);
  const toggleFunc = useCallback(() => setToggle(!toggle), [toggle]);

  // Hide the dropdown menu when the user clicks on a link and it is expanded,
  // also hide the menu if it is expanded (i.e in small screen responsive view)
  const handleCollapse = () => {
    const dropdownToggle = document.querySelector(".dropdown-toggle");
    if (
      dropdownToggle &&
      dropdownToggle.getAttribute("aria-expanded") === "true"
    ) {
      // user click to hide, .hide() reloads the page in Safari!
      dropdownToggle.click();
    }
    // hide the menu if it is expanded (i.e in small screen responsive view)
    if (!toggle) {
      toggleFunc();
    }
  };

  const links = [
    { label: "Letters", path: "/letter", children: [] },
    { label: "Map", path: "/map", children: [] },
    {
      label: "Teaching Resources",
      path: "/teaching-resources",
      children: [],
    },

    {
      label: "Virtual Exhibition",
      path: "/virtual-exhibition",
      children: [],
    },
    {
      label: navigationData["explanatory-essay"].label,
      path: navigationData["explanatory-essay"].path,
      children: navigationData["explanatory-essay"].children,
    },
    {
      label: "About",
      path: "/about",
      children: [],
    },
  ];

  return (
    <nav className="navbar navbar-dark navbar-expand-lg bg-primary">
      <div className="container">
        <Link
          to="/"
          className="navbar-brand m-0 me-md-2"
          onClick={handleCollapse}
        >
          The Letters of Richard Cobden
        </Link>

        <button
          className={`navbar-toggler ${toggle ? "collapsed" : ""}`}
          type="button"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleFunc}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`navbar-collapse collapse ${toggle ? "" : "show"}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {links.map((l, i) => {
              if (l.children.length === 0) {
                return (
                  <li className="nav-item" key={i}>
                    <NavLink
                      to={l.path}
                      className="nav-link"
                      onClick={handleCollapse}
                    >
                      {l.label}
                    </NavLink>
                  </li>
                );
              } else {
                return (
                  <Fragment key={i}>
                    <li className="nav-item dropdown navbar-dark" key={i}>
                      <NavLink
                        to="/explanatory-essay"
                        className="nav-link dropdown-toggle ps-0"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {l.label}
                      </NavLink>
                      <ul className="dropdown-menu">
                        {l.children.map((child, j) => (
                          <li key={j}>
                            <NavLink
                              to={`${l.path}/${child.path}`}
                              className="dropdown-item"
                              onClick={handleCollapse}
                            >
                              {child.label}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </Fragment>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;

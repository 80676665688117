import { Outlet, matchRoutes, useLocation } from "react-router-dom";

import Footer from "./Footer";
import Header from "./Header.jsx";

const Page = () => {
  const routes = [{ path: "/" }];
  // const routes = [];

  const location = useLocation();
  const matches = matchRoutes(routes, location);

  const justifyClass =
    matches && matches.length > 0
      ? "justify-content-center"
      : "justify-content-start";

  return (
    <div className="page-container d-flex flex-column">
      <Header />
      <section
        className={`container d-flex flex-column ${justifyClass} flex-grow-1`}
      >
        <Outlet />
      </section>
      <Footer />
    </div>
  );
};

export default Page;

import PropTypes from "prop-types";

import AggregationShow from "./AggregationShow";

const AggregationList = ({
  aggregations,
  pathname,
  include = [],
  columns = "col-12",
  chart = true,
}) => {
  return (
    <div className="row">
      {Object.keys(aggregations)
        .filter((aggregation) => aggregation !== "year")
        .map((aggregation) => {
          if (aggregations[aggregation].buckets.length === 0) {
            return null;
          }
          return (
            <AggregationShow
              key={aggregation}
              aggregation={aggregations[aggregation]}
              pathname={pathname}
              columns={columns}
              chart={chart}
            />
          );
        })}
    </div>
  );
};

AggregationList.propTypes = {
  aggregations: PropTypes.object,
  include: PropTypes.array,
  filterTitle: PropTypes.string,
};

export default AggregationList;

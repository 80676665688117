import { Link } from "react-router-dom";

import HtmlSnippet from "../layout/HtmlSnippet";

const getData = (highlight, letter, type) => {
  return (highlight && highlight[`${type}Html`]) || (letter && letter[type]);
};

const DisplayLetter = ({ letter, highlight, search = true }) => {
  const hasText = getData(highlight, letter, "text");
  const hasTo = getData(highlight, letter, "to");
  const hasLocation = getData(highlight, letter, "location");
  const hasAddress = letter && letter.addressHtml && letter.addressHtml !== "";
  const hasDate = letter && letter.date;
  const hasHeader = hasText || hasTo || hasLocation || hasAddress || hasDate;
  const hasBody = getData(highlight, letter, "body");

  return (
    <>
      <div className="">
        {hasHeader && (
          <div className="my-4 p-3 border border-secondary">
            {hasText && (
              <div>
                <strong>Text: </strong>
                {highlight && highlight.textHtml ? (
                  <>
                    {highlight.textHtml.map((snippet, i) => (
                      <HtmlSnippet key={i} snippet={snippet} element="span" />
                    ))}
                  </>
                ) : (
                  <HtmlSnippet snippet={letter.textHtml} element="span" />
                )}
                {search && (
                  <Link to={`/letter?_filters[]=text.raw|${letter.text}`}>
                    (Search: "{letter.text}")
                  </Link>
                )}
                {/* [
                      <Link
                        to={`/letter?_filters[]=standardisedText.raw|${letter.standardisedText}`}
                      >
                        Search Standardised: "{letter.standardisedText}"
                      </Link>
                      ] */}
              </div>
            )}

            {hasTo && (
              <div>
                <strong>To: </strong>
                {highlight && highlight.toHtml ? (
                  <>
                    {highlight.toHtml.map((snippet, i) => (
                      <HtmlSnippet key={i} snippet={snippet} element="span" />
                    ))}
                  </>
                ) : (
                  <HtmlSnippet snippet={letter.toHtml} element="span" />
                )}
                {search && (
                  <Link to={`/letter?_filters[]=to.raw|${letter.to}`}>
                    (Search: "{letter.to}")
                  </Link>
                )}
                {/* [
                      <Link
                        to={`/letter?_filters[]=standardisedTo.raw|${letter.standardisedTo}`}
                      >
                        Search Standardised: "{letter.standardisedTo}"
                      </Link>
                      ] */}
              </div>
            )}

            {hasLocation && (
              <div>
                <strong>Location: </strong>
                {highlight && highlight.locationHtml ? (
                  <>
                    {highlight.locationHtml.map((snippet, i) => (
                      <HtmlSnippet key={i} snippet={snippet} element="span" />
                    ))}
                  </>
                ) : (
                  <HtmlSnippet snippet={letter.locationHtml} element="span" />
                )}
                {search && (
                  <Link
                    to={`/letter?_filters[]=location.raw|${letter.location}`}
                  >
                    (Search: "{letter.location}")
                  </Link>
                )}
                {/* [
                      <Link
                        to={`/letter?_filters[]=standardisedLocation.raw|${letter.standardisedLocation}`}
                      >
                        Search Standardised: "
                        {letter.standardisedLocation}"
                      </Link>
                      ] */}
              </div>
            )}

            {hasAddress && (
              <div>
                <strong>Address: </strong>
                {highlight && highlight.addressHtml ? (
                  <>
                    {highlight.addressHtml.map((snippet, i) => (
                      <HtmlSnippet key={i} snippet={snippet} element="span" />
                    ))}
                  </>
                ) : (
                  <HtmlSnippet snippet={letter.addressHtml} element="span" />
                )}
              </div>
            )}

            {hasDate && (
              <div>
                <strong>Date: </strong>
                {letter.dateDescription}
              </div>
            )}
          </div>
        )}

        {hasBody && (
          <div className="mb-4 text-break">
            {highlight && highlight.body ? (
              <>
                {highlight.body.map((snippet, i) => (
                  <HtmlSnippet key={i} snippet={snippet} />
                ))}
              </>
            ) : (
              <HtmlSnippet snippet={letter.body} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DisplayLetter;

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TeachingResources = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Teaching Material`;

  return (
    <div className="container page-main">
      <div className="mb-5">
        <h1 className="mb-4">Our Time: Active Citizenship</h1>
        <h2>Background</h2>
        <p>
          Richard Cobden was the quintessential ‘active citizen’. His letters
          provide an excellent gateway to themes around the evolution of British
          democracy and the origins of transnational co-operation, allowing
          instructive and critical comparison between past and present. Cobden
          became an important figure in British politics for his work around
          democracy, campaigning, and organisation.
        </p>
        <p>
          The Arts and Humanities Research Council Project
          <em>
            {" "}
            The Letters of Richard Cobden (1804-65) Online: an exploration in
            active citizenship
          </em>{" "}
          (led by Leeds Beckett University) wanted to use the collection of
          Cobden’s transcribed letters to promote the values and practices of
          active citizenship to secondary school pupils and to increase
          awareness of the relevance of his ideas of ‘constitutional’ pressure
          to a modern political world. The GreenWing Project was brought into
          this collaboration to create a programme that would help secondary
          school learners explore what active citizenship has meant through time
          and explore active citizenship in their local area, creating
          educational resources and campaigns to promote active citizenship to
          other young people.
        </p>
        <h2>Our Time: Active Citizenship</h2>
        <p>
          The <strong>intent </strong>for this project is that the co-created
          workshop and resources can be used as a cross-curricular project for
          KS3 which specifically targets Citizenship, History and English. In
          the co-creation of these resources, the GreenWing Project (Rachel Wood
          and Mark Jamieson) used leadership coaching to frame the main Our
          Time: Active Citizenship workshop. Year 9 Leaders were given the
          opportunity to practise their conceptual and reflective learning in a
          live context and to develop their unique leadership style. They were
          tasked with leading groups of Year 7 or Year 8 active citizens or
          historians to organise and create an active citizenship campaign about
          something that felt passionate about changing in society meaning that
          the leaders led their younger peers to become Active Citizenship
          Emissaries in their own right. The approach of Team-Based Learning
          (TBL) has a specific sequence to encourage optimal learning within
          group work and was <strong>implemented</strong> to develop all
          learners’ understanding of history, citizenship, and reform through
          time and how it links to the national picture.&nbsp;
        </p>
        <h2>Using these resources, learners will:</h2>
        <ul>
          <li>
            Understand strengths of self and others and how these can be used
            for active leadership and active citizenship
          </li>
          <li>
            Participate in forms of responsible activity, think critically and
            debate political questions
          </li>
          <li>
            Use leadership as a positive focus to reorient and develop future
            ambitions at personal, community and societal levels.
          </li>
          <li>
            Apply new skills of active citizenship, thinking and behaviours in a
            live context
          </li>
          <li>
            Use reflective exercises and conceptual leadership frameworks to
            really understand the significance of reform
          </li>
          <li>
            Identify historic examples/ case studies of active citizenship and
            use primary historical sources to support ideas
          </li>
          <li>
            Use discussion to learn, elaborate, and explain understanding and
            ideas
          </li>
          <li>
            Develop a sense of awareness and pride in their local area’s
            heritage
          </li>
          <li>
            Develop individual and group active citizenship projects as part of
            a broader contribution to the local community.
          </li>
          <li>
            Speak confidently and effectively when giving short speeches and
            making presentations
          </li>
        </ul>
        <p>
          The main <strong>impact</strong> for this project is that learners
          will become confident researchers, historians, communicators, and
          active citizens. The model ensures focus, enjoyment, engagement and
          importantly socialisation through its step-by-step process.
          Importantly, it also promotes sustainability of these skills as it is
          young people who continue to lead other young people.
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <div className="">
          <a
            className="btn btn-primary btn-lg"
            href={`${process.env.REACT_APP_SAN_URL}/the-letters-of-richard-cobden-teaching-resources.zip`}
          >
            <FontAwesomeIcon icon={faDownload} className="me-2" /> Download
            Resources
          </a>
        </div>
      </div>
    </div>
  );
};

export default TeachingResources;

import { Outlet } from "react-router-dom";

import useResults from "../hooks/useResults";
import Error from "../layout/Error";
import Loading from "../layout/Loading";

const VirtualExhibitionHome = () => {
  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Virtual Exhibition`;
  const apiPath = `${process.env.REACT_APP_BACKEND_URL}/search/virtual_exhibits`;

  const { total, items, aggregations, pagination, isLoading, error } =
    useResults(apiPath);
  const letters = items.map((item) => item._source);

  const archives = letters.reduce((archives, letter) => {
    const archive = letter.archive;
    const archive_slug = letter.archive_slug;
    const letter_slug = letter.letter_slug;

    if (!archives[archive_slug]) {
      archives[archive_slug] = {
        archive,
        archive_slug,

        letters: [],
      };
    }

    archives[archive_slug].letters.push({
      letter: letter.letter,
      letter_slug,
      xml_slug: letter.xml_slug,
      caption: letter.caption,
      keywords: letter.keywords,
      pages: letter.pages,
      images: letter.images.sort((a, b) => {
        if (a.filename < b.filename) {
          return -1;
        }
        if (a.filename > b.filename) {
          return 1;
        }
        return 0;
      }),
    });

    return archives;
  }, {});

  const results = Object.keys(archives).map(
    (archive_slug) => archives[archive_slug]
  );

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {error.message !== null ? (
            <Error error={error} />
          ) : (
            <Outlet context={[results, aggregations]} />
          )}
        </>
      )}
    </div>
  );
};

export default VirtualExhibitionHome;

import { useState } from "react";

import PropTypes from "prop-types";

import AggregationBucket from "./AggregationBucket";
import AggregationPieChart from "./AggregationPieChart";
import COLORS from "../utils/colors";

const AggregationShow = ({
  aggregation,
  pathname,
  columns = "col-12",
  chart = true,
}) => {
  const defaultSize = 5;
  const expandedSize = 20;
  let defaultLabel = "show all";
  let size = defaultSize;
  let resizable = true;

  const [options, setOptions] = useState({
    defaultSize: defaultSize,
    expandedSize: expandedSize,
    resizable: resizable,
    resizeLabel: defaultLabel,
    size: size,
  });

  const handleResizeClick = () => {
    let newOptions = { ...options };

    switch (true) {
      case options.size === options.defaultSize:
        newOptions.size = aggregation.cardinality;
        newOptions.resizeLabel = "show fewer";
        break;
      case options.size === aggregation.cardinality:
        newOptions.size = options.defaultSize;
        newOptions.resizeLabel = "show all";
        break;
      default:
        newOptions.size = options.defaultSize;
        newOptions.resizeLabel = "show all";
    }

    setOptions(newOptions);
  };

  // // TODO: manage aggregation name and field name separately from backend
  // let aggregationName = label === "Case Study" ? "caseStudyTitle" : label;

  // aggregationName =
  //   aggregationName === "Places" ? "placeTags" : aggregationName;

  // aggregationName = aggregationName[0].toLowerCase() + aggregationName.slice(1);

  return (
    <div className={`${columns} mb-3`}>
      <div className="p-3 border border-primary rounded-3">
        <div className="">
          <div className="">
            <span className="pspr-key">
              {/* <i className={`fa fa-fw fa-${aggregation.icon} me-2 mb-2`}></i> */}
              {/* TODO add icon for aggregation? */}
              {aggregation.label} ({aggregation.cardinality})
            </span>
          </div>
        </div>
        <div className="">
          {chart &&
            aggregation.buckets.filter((bucket) => bucket.doc_count > 0)
              .length > 0 && (
              <div className="ratio ratio-1x1" style={{ maxWidth: "100px" }}>
                <AggregationPieChart buckets={aggregation.buckets} />
              </div>
            )}

          <div
            className="col pspr-key custom-scrollbar"
            style={{
              maxHeight: "450px",
              overflowY: "scroll",
            }}
          >
            <div className="">
              <div className="col-12">
                {aggregation.buckets
                  .slice(0, options.size)
                  .map((value, index) => {
                    const color = COLORS[index % COLORS.length];
                    return (
                      <AggregationBucket
                        key={value.key}
                        aggregationName={aggregation.field}
                        aggregationLabel={aggregation.label}
                        name={`${value.key}`}
                        count={value.doc_count}
                        pathname={pathname}
                        color={color}
                      />
                    );
                  })}
              </div>
              <div className="col mt-2">
                {options.resizable && (
                  <button
                    className="col btn btn-sm btn-link float-end"
                    onClick={handleResizeClick}
                  >
                    {options.resizeLabel}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AggregationShow.propTypes = {
  aggregation: PropTypes.object,
  pathname: PropTypes.string,
};

export default AggregationShow;

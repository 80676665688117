import Navbar from "./Navbar";
const Header = () => {
  return (
    <header className="sticky-top mb-4">
      <Navbar />
      <div className="floral"></div>
    </header>
  );
};

export default Header;
